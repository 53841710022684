import { initClient } from '@sunrise/client'
import { defineAsyncComponent } from 'vue'
import { initHotReload } from './hotReload'

const dynamicImports = {
    M_TechnicalData_Stage_Hydrated: defineAsyncComponent(
        () =>
            import(
                './cmsElements/M/M_TechnicalData_Stage/M_TechnicalData_Stage_Hydrated/M_TechnicalData_Stage_Hydrated.vue'
            ),
    ),
    E_Repareo_Widget_Hydrated: defineAsyncComponent(
        () => import('./cmsElements/E/E_Repareo_Widget/E_Repareo_Widget_Hydrated.vue'),
    ),
    E_Wkda_Widget_Hydrated: defineAsyncComponent(
        () => import('./cmsElements/E/E_Wkda_Widget/E_Wkda_Widget_Hydrated.vue'),
    ),
    M_AMS_Resultlist_Hydrated: defineAsyncComponent(
        () => import('./cmsElements/M/M_AMS_Resultlist/M_AMS_Resultlist_Hydrated.vue'),
    ),
    M_AMS_Slider_Hydrated: defineAsyncComponent(() => import('./cmsElements/M/M_AMS_Slider/M_AMS_Slider_Hydrated.vue')),
    AmsSearchbox: defineAsyncComponent(() => import('./components/AmsSearchbox/AmsSearchbox.vue')),
    M_TechnicalData_Model_Stage_Hydrated: defineAsyncComponent(
        () =>
            import(
                './cmsElements/M/M_TechnicalData_Model_Stage/M_TechnicalData_Model_Stage_Hydrated/M_TechnicalData_Model_Stage_Hydrated.vue'
            ),
    ),
}
initClient({
    adsEnabled: true,
    dynamicImports,
})

initHotReload()
